var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-dialog',{attrs:{"width":"650","overlay-opacity":"0.8"},model:{value:(_vm.syncedOpened),callback:function ($$v) {_vm.syncedOpened=$$v},expression:"syncedOpened"}},[_c('div',{staticClass:"standart-modal-card"},[_c('div',{staticClass:"standart-modal-card-top d-flex align-center justify-space-between"},[_c('h3',[_vm._v("Venue schedule")]),_c('v-btn',{attrs:{"icon":"","color":"black"},on:{"click":_vm.closeModal}},[_c('v-icon',[_vm._v("mdi-close")])],1)],1),_c('div',{staticClass:"standart-modal-card-middle"},[_c('v-row',{staticClass:"align-center"},[_c('v-col',{staticClass:"mb-3 font-weight-bold",attrs:{"cols":"2"}},[_vm._v("Set time to all days:")]),_c('v-col',{attrs:{"cols":"5"}},[_c('ScheduleTimePicker',{attrs:{"timeFormat":_vm.timeFormat,"actionTime":'opening_time',"title":'Opening',"time":_vm.generalTime.opening_time,"save":_vm.setTime}})],1),_c('v-col',{attrs:{"cols":"5"}},[_c('ScheduleTimePicker',{attrs:{"timeFormat":_vm.timeFormat,"actionTime":'closing_time',"title":'Closing',"time":_vm.generalTime.closing_time,"save":_vm.setTime}})],1),_c('Button',{staticClass:"standart-button mx-9",attrs:{"styleType":"secondary","text":"Set time","type":"button"},on:{"handleClick":_vm.setGeneralTime}})],1),_vm._l((_vm.curSchedules),function(day,index){return _c('v-row',{key:day.id,staticClass:"align-end"},[_c('v-col',{staticClass:"mb-2 font-weight-bold",attrs:{"cols":"2"}},[_vm._v(_vm._s(day.opening_day))]),_c('v-col',{attrs:{"cols":"5"}},[_c('ScheduleTimePicker',{attrs:{"day":{
            id: day.id,
            title: 'opening_day',
            value: day.opening_day,
          },"timeFormat":_vm.timeFormat,"actionTime":'opening_time',"title":'Opening',"time":day.opening_time,"save":_vm.save,"required":_vm.requiredFields[`open-${day.opening_day.toLowerCase()}`]}})],1),_c('v-col',{attrs:{"cols":"4"}},[_c('ScheduleTimePicker',{attrs:{"day":{
            id: day.id,
            title: 'closing_day',
            value: day.closing_day,
          },"timeFormat":_vm.timeFormat,"actionTime":'closing_time',"title":'Closing',"time":day.closing_time,"save":_vm.save,"required":_vm.requiredFields[`close-${day.closing_day.toLowerCase()}`]}})],1),_c('v-col',{attrs:{"cols":"1"}},[_c('v-btn',{attrs:{"text":"","icon":""},on:{"click":function($event){return _vm.duplicateDay(day, index)}}},[(!_vm.isDayAfter(day, index))?_c('v-icon',[_vm._v("mdi-plus")]):_c('v-icon',[_vm._v("mdi-minus")])],1)],1)],1)})],2),_c('div',{staticClass:"standart-modal-card-bottom"},[_c('div',{staticClass:"d-flex align-center justify-end"},[_c('Button',{staticClass:"standart-button-content-width mr-2",attrs:{"styleType":"secondary","text":"Cancel","type":"button"},on:{"handleClick":_vm.closeModal}}),_c('Button',{staticClass:"standart-button-content-width",attrs:{"text":"Save","type":"button","is-loading":_vm.isScheduleLoading},on:{"handleClick":_vm.saveSchedule}})],1)])])])
}
var staticRenderFns = []

export { render, staticRenderFns }