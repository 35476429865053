<template>
  <div>
    <v-form ref="venueForm" v-model="isFormValid" @submit.prevent="validateForm('venueForm')">
      <v-row no-gutters class="justify-space-between">
        <v-col cols="12" class="d-flex align-center">
          <v-icon> mdi-food-outline </v-icon>
          <h3 class="form-title ml-4">General Info</h3>
        </v-col>
        <v-col lg="12" md="12" sm="12" class="d-flex justify-md-end justify-sm-start align-center mt-sm-4">
          <v-row>
            <v-col class="d-flex flex-column" cols="4">
              <div v-if="!isNew && hasAvailability">
                <div v-if="woltSettingsPresent" class="status-tag mb-1">
                  <div :style="`background-color: ${statusColor('wolt')}`" class="status-circle"></div>
                  <h3>Wolt Status</h3>
                </div>
                <div v-if="boltSettingsPresent" class="status-tag mb-1">
                  <div :style="`background-color: ${statusColor('bolt')}`" class="status-circle"></div>
                  <h3>Bolt Status</h3>
                </div>
                <div v-if="fudySettingsPresent" class="status-tag mb-1">
                  <div :style="`background-color: ${statusColor('fudy')}`" class="status-circle"></div>
                  <h3>Fudy Status</h3>
                </div>
              </div>
              <Button v-if="shouldPopulateForm && hasAvailability" text="Change status"
                class="standart-button-content-width mr-1" type="button" @handleClick="openVenueModal" />
            </v-col>
            <v-col class="d-flex flex-column" cols="4">
              <Button v-if="shouldPopulateForm && woltSettingsPresent" text="Check Wolt"
                class="standart-button-content-width mb-1" type="button" :isLoading="isCheckingWolt"
                @handleClick="checkWolt" />
              <Button v-if="shouldPopulateForm && boltSettingsPresent" text="Check Bolt"
                class="standart-button-content-width mb-1" type="button" :isLoading="isCheckingBolt"
                @handleClick="checkBolt" />
              <Button v-if="shouldPopulateForm && fudySettingsPresent" text="Check Fudy"
                class="standart-button-content-width mb-1" type="button" :isLoading="isCheckingFudy"
                @handleClick="checkFudy" />
              <Button v-if="shouldPopulateForm" text="Check Rkeeper" class="standart-button-content-width mb-1"
                type="button" :isLoading="isCheckingRk" @handleClick="checkRk" />
            </v-col>
            <v-col class="d-flex flex-column" cols="4">
              <Button v-if="shouldPopulateForm" type="button" text="Change schedule"
                class="standart-button-content-width mb-1" @handleClick="openVenueScheduleModal">
              </Button>
              <Button v-if="shouldPopulateForm" text="Sync menu" class="standart-button-content-width mr-1" type="button"
                :isLoading="isFormLoading" @handleClick="openMenuModal" />
              <Button v-if="getUserData.roleNames[0].toLowerCase() !== 'manager'" text="Change subgroup"
                class="standart-button-content-width mt-1" type="button" @handleClick="openVenueGroupModal" />
            </v-col>
          </v-row>
        </v-col>
      </v-row>

      <v-text-field class="standart-input-filled standart-input-no-message mt-4 mb-3" v-model="form.name" label="Name"
        :disabled="!isFormEditable" :rules="[rules.required]" color="dark_grey" filled rounded dense></v-text-field>

      <div class="d-flex justify-space-between">
        <h3 class="form-title mt-2 mb-6">RKeeper settings</h3>
        <v-btn icon large color="#5B7FFE" @click="isSettingsOpen.rKeeperSettings = !isSettingsOpen.rKeeperSettings">
          <v-icon>{{ isSettingsOpen.rKeeperSettings ? 'mdi-minus' : 'mdi-plus' }}</v-icon>
        </v-btn>
      </div>

      <v-row class="mb-8" v-show="isSettingsOpen.rKeeperSettings">
        <v-col class="py-0" cols="6" v-for="key of Object.keys(form.settings.rKeeperSettings)" :key="key">
          <v-autocomplete v-if="fieldTypes[key] && fieldTypes[key].type === 'select'"
            v-model="form.settings.rKeeperSettings[key]" :label="key" :disabled="!isFormEditable"
            class="standart-input-filled standart-input-no-message mb-6" :rules="[rules.required]" color="dark_grey"
            filled rounded dense :items="key === 'defaultMenuLanguageId' ? languages : deliveryMethods"
            :item-text="getItemText" item-value="id" @input="selectLang($event, key)">
          </v-autocomplete>

          <v-checkbox v-else-if="fieldTypes[key] && fieldTypes[key].type === 'checkbox'"
            v-model="form.settings.rKeeperSettings[key]" :disabled="!isFormEditable" :label="key" />

          <v-text-field v-else v-model="form.settings.rKeeperSettings[key]" :label="key"
            class="standart-input-filled standart-input-no-message mb-6" :disabled="!isFormEditable"
            :rules="isRequiredField(key) ? [rules.required] : []" type="text" color="dark_grey" filled rounded
            dense></v-text-field>
        </v-col>
      </v-row>

      <div class="d-flex justify-space-between">
        <h3 class="form-title mt-2 mb-6 d-flex align-center">
          <span>Wolt settings</span>
          <v-switch class="ma-0 ml-4" v-model="woltSwitch" dense hide-details></v-switch>
        </h3>
        <v-btn v-if="woltSwitch" icon large color="#5B7FFE"
          @click="isSettingsOpen.woltSettings = !isSettingsOpen.woltSettings">
          <v-icon>{{ isSettingsOpen.woltSettings ? 'mdi-minus' : 'mdi-plus' }}</v-icon>
        </v-btn>
      </div>

      <v-row class="mb-8" v-if="isSettingsOpen.woltSettings && woltSwitch">
        <v-col class="py-0" cols="6" v-for="key of Object.keys(this.form.settings.woltSettings)" :key="key">
          <v-autocomplete v-if="key == 'url'" :label="key" class="standart-input-filled standart-input-no-message mb-6"
            :items="woltSettingsUrl" v-model="form.settings.woltSettings[key]" color="dark_grey" filled rounded dense>
          </v-autocomplete>
          <v-text-field v-else-if="key == 'venueId'" v-model="form.settings.woltSettings[key]" :label="key"
            class="standart-input-filled standart-input-no-message mb-6" :disabled="!isFormEditable"
            :rules="woltSwitch ? [rules.required] : []" type="text" color="dark_grey"
            :append-icon="isWoltVenueIdCopied ? 'mdi-check' : 'mdi-content-copy'"
            @click:append="copyClipboard('isWoltVenueIdCopied', form.settings.woltSettings[key])" filled rounded
            dense></v-text-field>
          <v-text-field v-else v-model="form.settings.woltSettings[key]" :label="key"
            class="standart-input-filled standart-input-no-message mb-6" :disabled="!isFormEditable"
            :rules="woltSwitch ? [rules.required] : []" type="text" color="dark_grey" filled rounded dense></v-text-field>
        </v-col>
      </v-row>

      <div class="d-flex justify-space-between">
        <h3 class="form-title mt-2 mb-6 d-flex align-center">
          <span>Fudy settings</span>
          <v-switch class="ma-0 ml-4" v-model="fudySwitch" dense hide-details></v-switch>
        </h3>
        <v-btn v-if="fudySwitch" icon large color="#5B7FFE"
          @click="isSettingsOpen.fudySettings = !isSettingsOpen.fudySettings">
          <v-icon>{{ isSettingsOpen.fudySettings ? 'mdi-minus' : 'mdi-plus' }}</v-icon>
        </v-btn>
      </div>

      <v-row class="mb-8" v-if="isSettingsOpen.fudySettings && fudySwitch">
        <v-col class="py-0" cols="6" v-for="key of Object.keys(this.form.settings.fudySettings)" :key="key">
          <v-autocomplete v-if="key == 'url'" :label="key" class="standart-input-filled standart-input-no-message mb-6"
            :items="fudySettingsUrl" v-model="form.settings.fudySettings[key]" color="dark_grey" filled rounded dense>
          </v-autocomplete>
          <v-text-field v-else-if="key == 'venueId'" v-model="form.settings.fudySettings[key]" :label="key"
            class="standart-input-filled standart-input-no-message mb-6" :disabled="!isFormEditable"
            :rules="fudySwitch ? [rules.required] : []" type="text" color="dark_grey"
            :append-icon="isFudyVenueIdCopied ? 'mdi-check' : 'mdi-content-copy'"
            @click:append="copyClipboard('isFudyVenueIdCopied', form.settings.fudySettings[key])" filled rounded
            dense></v-text-field>
          <v-text-field v-else v-model="form.settings.fudySettings[key]" :label="key"
            class="standart-input-filled standart-input-no-message mb-6" :disabled="!isFormEditable"
            :rules="fudySwitch ? [rules.required] : []" type="text" color="dark_grey" filled rounded dense></v-text-field>
        </v-col>
      </v-row>

      <div class="d-flex justify-space-between">
        <h3 class="form-title mt-2 mb-6">Venue Self settings</h3>
        <v-btn icon large color="#5B7FFE" @click="isSettingsOpen.venueSelfSettings = !isSettingsOpen.venueSelfSettings">
          <v-icon>{{ isSettingsOpen.venueSelfSettings ? 'mdi-minus' : 'mdi-plus' }}</v-icon>
        </v-btn>
      </div>

      <v-row class="mb-8" v-show="isSettingsOpen.venueSelfSettings">
        <v-col class="py-0" cols="6" v-if="!isNew">
          <v-text-field v-model="form.id" label="Venue Self Id"
            class="standart-input-filled standart-input-no-message mb-6" :readonly="!isNew" :rules="[rules.required]"
            type="text" color="dark_grey" :append-icon="isVenueSelfIdCopied ? 'mdi-check' : 'mdi-content-copy'"
            @click:append="copyClipboard('isVenueSelfIdCopied', form.id)" filled rounded dense>
          </v-text-field>
        </v-col>
        <v-col class="py-0" cols="6" v-if="!isNew">
          <v-text-field :value="formatDateTime(dateTimeFormats, form.menuUpdateTimeUtc)" label="Last menu sync time"
            class="standart-input-filled standart-input-no-message mb-6" :readonly="!isNew" :rules="[rules.required]"
            type="text" color="dark_grey" filled rounded dense>
          </v-text-field>
        </v-col>
        <v-col class="py-0" cols="6" v-for="key of 
  Object.keys(this.form.settings.venueSelfSettings).filter(
    el => el !== 'boltExpiredDateUtc' && el !== 'woltExpiredDateUtc' && el !== 'fudyExpiredDateUtc',
  )
" :key="key">
          <v-checkbox v-if="fieldTypes[key] && fieldTypes[key].type === 'checkbox'"
            v-model="form.settings.venueSelfSettings[key]"
            :disabled="key === 'useDayLigthSavingTime' ? !form.settings.venueSelfSettings.useCustomTimeZone : !isFormEditable"
            :label="key" />

          <v-row v-else-if="fieldTypes[key] && fieldTypes[key].type === 'select'">
            <v-col v-if="key === 'timeZone'">
              <v-autocomplete v-model="form.settings.venueSelfSettings[key]" label="Time zone"
                class="standart-input-filled standart-input-no-message mb-6" color="dark_grey" :items="timeZones"
                item-text="name" item-value="id" :rules="[rules.required]"
                :disabled="!form.settings.venueSelfSettings.useCustomTimeZone" loader-height="10" filled rounded dense>
              </v-autocomplete>
            </v-col>
            <v-col v-else cols="8">
              <v-autocomplete v-model="form.settings.venueSelfSettings[key]" :label="key"
                :disabled="!isFormEditable || isSelfImageSourceId"
                class="standart-input-filled standart-input-no-message mb-6" color="dark_grey" :items="getIdNames"
                item-text="name" item-value="id" @focus="getIdNamesFocused" :loading="idNamesLoading"
                @input="selectImageSourceId" loader-height="10" filled rounded dense>
              </v-autocomplete>
            </v-col>
            <v-col v-if="key !== 'timeZone'" cols="4">
              <v-checkbox v-model="isSelfImageSourceId" :disabled="!isFormEditable" label="Select this venue" />
            </v-col>
          </v-row>

          <v-text-field v-else-if="fieldTypes[key] && fieldTypes[key].type === 'number'"
            v-model="form.settings.venueSelfSettings[key]" :disabled="!isFormEditable" :label="key"
            class="standart-input-filled standart-input-no-message mb-6" type="number" filled rounded dense min="0"
            max="420" />

          <v-text-field v-else v-model="form.settings.venueSelfSettings[key]" :label="key"
            class="standart-input-filled standart-input-no-message mb-6" :disabled="!isFormEditable"
            :rules="[rules.required]" type="text" color="dark_grey" filled rounded dense></v-text-field>
        </v-col>
      </v-row>

      <div class="d-flex justify-space-between">
        <h3 class="form-title mt-2 mb-6 d-flex align-center">
          <span>Bolt settings</span>
          <v-switch class="ma-0 ml-4" v-model="boltSwitch" dense hide-details></v-switch>
        </h3>
        <v-btn v-if="boltSwitch" icon large color="#5B7FFE"
          @click="isSettingsOpen.boltSettings = !isSettingsOpen.boltSettings">
          <v-icon>{{ isSettingsOpen.boltSettings ? 'mdi-minus' : 'mdi-plus' }}</v-icon>
        </v-btn>
      </div>

      <template v-if="isSettingsOpen.boltSettings && this.boltSwitch">
        <v-row class="mb-8" v-show="isSettingsOpen.boltSettings">
          <v-col class="py-0" cols="6" v-for=" key  of  Object.keys(this.form.settings.boltSettings) " :key="key">
            <v-autocomplete v-if="key == 'url'" :label="key" class="standart-input-filled standart-input-no-message mb-6"
              :items="boltSettingsUrl" v-model="form.settings.boltSettings[key]" color="dark_grey" filled rounded
              dense></v-autocomplete>
            <v-text-field v-else v-model="form.settings.boltSettings[key]" :label="key"
              class="standart-input-filled standart-input-no-message mb-6" :disabled="!isFormEditable"
              :rules="boltSwitch ? [rules.required] : []" type="text" color="dark_grey" filled rounded
              dense></v-text-field>
          </v-col>
        </v-row>
      </template>

      <div class="d-flex justify-space-between">
        <h3 class="form-title mt-2 mb-6 d-flex align-center">Licenses</h3>
        <v-btn icon large color="#5B7FFE" @click="isSettingsOpen.licenses = !isSettingsOpen.licenses">
          <v-icon>{{ isSettingsOpen.licenses ? 'mdi-minus' : 'mdi-plus' }}</v-icon>
        </v-btn>
      </div>

      <template v-if="isSettingsOpen.licenses">
        <v-row class="mb-8">
          <v-col cols="6" v-for=" expiredDate  in  ['boltExpiredDateUtc', 'woltExpiredDateUtc', 'fudyExpiredDateUtc'] "
            :key="expiredDate">
            <v-row no-gutters class="d-flex align-bottom">
              <v-col cols="8">
                <v-datetime-picker :disabled="isLicenseEditDisabled" :textFieldProps="dateTimeTextFieldProps"
                  :label="expiredDate" v-model="form.settings.venueSelfSettings[expiredDate]" :dateFormat="dateFormat"
                  :timeFormat="timeFormat" @input="dateTimeChanged(expiredDate)">
                  <template v-slot:dateIcon>
                    <v-icon color="blue darken-2"> mdi-calendar-range </v-icon>
                  </template>
                  <template v-slot:timeIcon>
                    <v-icon color="blue darken-2"> mdi-clock-outline </v-icon>
                  </template>
                </v-datetime-picker>
              </v-col>
              <v-col cols="4">
                <div class="datetime-unlim-checkbox d-flex justify-center align-center">
                  <v-checkbox @change="unlimCheckboxChange($event, expiredDate)" v-model="expDateUnlimModel[expiredDate]"
                    :disabled="!isFormEditable || isLicenseEditDisabled" label="Unlimited" />
                </div>
              </v-col>
            </v-row>
          </v-col>
          <v-col v-if="isRequestLicenseAllowed" cols="12">
            <Button text="Request license" styleType="primary" type="button" class="standart-button-content-width"
              @handleClick="requestLicenseHandler" />
          </v-col>
        </v-row>
      </template>

      <div class="d-flex align-center justify-space-between mt-4">
        <Button text="Cancel" styleType="secondary" type="button" class="standart-button-content-width"
          @handleClick="resetForm" />
        <Button text="Save" styleType="primary" type="submit" class="standart-button-content-width"
          :isLoading="isFormLoading" />
      </div>
    </v-form>
    <v-alert class="custom-alert" dismissible type="error" close-icon="mdi-window-close" :icon="false"
      :value="showExpiredAlert">{{ expiredAlertMsg }}
    </v-alert>
    <VenueModal v-model="isVenueModalShow" :change-status="changeVenueStatus" :venue="venue" :isLoading="isFormLoading"
      :isWoltSettingsPresent="woltSettingsPresent" :isBoltSettingsPresent="boltSettingsPresent"
      :isFudySettingsPresent="fudySettingsPresent" />
    <MenuModal v-model="isMenuModalShow" :isLoading="isFormLoading" :venueId="venue.id" />
    <VenueScheduleModal v-model="isScheduleModalShow" :change-schedule="changeVenueSchedule"
      :is-schedule-loading="isScheduleLoading" :schedule="schedule.availability" />
    <SelectGroupModal v-model="selectionGroupModalShown" @saveGroupId="changeGroupId" />
    <RequestLicenseModal v-model="reqLicenseModalShown" @sendReqLicForm="sendReqLicHandler" />
    <ChangeVenueGroupModal v-model="isVenueGroupModalShow" @saveForm="saveEdit" :formData="venue"
      :venueGroups="venueGroupsData" />
  </div>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from 'vuex';
import { TOAST_TYPES, VENUE_STATUSES, VENUE_FIELDS, DEFAULT_SCHEDULE } from '@/data';
import Button from '@/components/commonComponents/Button';
import VenueModal from '@/components/modals/VenueModal';
import MenuModal from '@/components/modals/MenuModal';
import VenueScheduleModal from '@/components/modals/VenueScheduleModal';
import SelectGroupModal from '@/components/modals/SelectGroupModal';
import ChangeVenueGroupModal from '@/components/modals/ChangeVenueGroupModal';
import RequestLicenseModal from '@/components/modals/RequestLicenseModal';
import dateFormat from '../mixins/date-format';
import { TIME_ZONES } from '../data';

export default {
  name: 'VenueForm',
  components: {
    VenueModal,
    MenuModal,
    VenueScheduleModal,
    Button,
    SelectGroupModal,
    RequestLicenseModal,
    ChangeVenueGroupModal,
  },
  mixins: [dateFormat],
  data() {
    return {
      venueGroupsData: [],
      woltSettingsUrl: [
        { text: 'production environment', value: 'https://pos-integration-service.wolt.com' },
        { text: 'test environment', value: 'https://pos-integration-service.development.dev.woltapi.com' },
      ],
      boltSettingsUrl: [
        { text: 'production environment', value: 'https://node.bolt.eu/delivery-provider-pos/genericClient' },
      ],
      fudySettingsUrl: [
        { text: 'test environment', value: 'https://dev-api.fudy.eu' },
      ],
      isFormValid: false,
      fieldTypes: { ...VENUE_FIELDS },
      timeZones: [...TIME_ZONES],
      isSettingsOpen: {
        rKeeperSettings: false,
        woltSettings: false,
        venueSelfSettings: false,
        boltSettings: false,
        fudySettings: false,
        licenses: false,
      },
      schedule: {
        availability: [],
      },
      form: {
        id: '',
        name: '',
        groupId: this.venueGroupFormId,
        isOnline: null,
        menuUpdateTimeUtc: '',
        settings: {
          rKeeperSettings: {
            ip: '',
            port: '',
            login: '',
            pwd: '',
            stationIdent: '',
            tableIdent: '',
            waiterIdent: '',
            receiptMaketIdent: '',
            voidIdent: '',
            orderTypeIdent: '',
            taxIdent: '',
            pickUpOrderTypeIdent: '',
            woltRootSelectorIdent: '',
            boltRootSelectorIdent: '',
            fudyRootSelectorIdent: '',
            woltCurrencyIdent: '',
            boltCurrencyIdent: '',
            fudyCurrencyIdent: '',
            printPurposeIdent: '',
            defaultMenuLanguageId: '',
            defaultDeliveryMethod: '',
            comboTaxRate: '',
            discountId: null,
            isPayOrderEnabled: false,
            isDebugMode: false,
            isAutoPrintEnabled: false,
            isUseComboName: false,
            isUseKds: false,
          },
          woltSettings: {
            url: '',
            login: '',
            pwd: '',
            venueId: '',
            apiKey: '',
          },
          fudySettings: {
            url: "",
            venueId: "",
            apiKey: ""
          },
          venueSelfSettings: {
            woltExpiredDateUtc: '1969-12-31T18:00:00.000Z',
            boltExpiredDateUtc: '1969-12-31T18:00:00.000Z',
            fudyExpiredDateUtc: '1969-12-31T18:00:00.000Z',
            defaultCookingTimeInMinutes: 1,
            maxCookingTimeInMinutes: 0,
            defaultDelayTimeInMinutes: 0,
            alwaysDefaultDelayTime: false,
            autoAcceptTimeInSeconds: 60,
            imageSourceId: '',
            isPrintOrderEnabled: false,
            isAutoAcceptEnabled: false,
            isAutoPickedUpEnabled: false,
            isAutoSyncMenuEnabled: false,
            isSingleModifierModeEnabled: false,
            isPayOrderAfterAcceptEnabled: false,
            timeZone: null,
            useDayLigthSavingTime: false,
            useCustomTimeZone: false,
            delayOrderSaving: false,

          },
          boltSettings: {
            url: '',
            providerId: '',
          },
        },
      },
      rules: {
        required: value => {
          if (value === 0) {
            return true;
          }
          return !!value || 'Required field';
        },
      },
      isVenueSelfIdCopied: false,
      isWoltVenueIdCopied: false,
      isFudyVenueIdCopied: false,
      isNew: true,
      dateTimeTextFieldProps: {
        class: 'standart-input-filled standart-input-no-message',
        filled: true,
        rounded: true,
        dense: true,
      },
      isVenueModalShow: false,
      isMenuModalShow: false,
      isScheduleModalShow: false,
      isVenueGroupModalShow: false,
      isAreaModalShow: false,
      isSyncingMenu: false,
      dateTimeFormats: {
        dateOrder: 0,
        dateSeparator: '.',
        timeFormat: 0,
      },
      expDateUnlimModel: {
        woltExpiredDateUtc: false,
        boltExpiredDateUtc: false,
        fudyExpiredDateUtc: false,
      },
      woltSwitch: true,
      boltSwitch: true,
      fudySwitch: true,
      isSelfImageSourceId: false,
      idNamesLoading: false,
      getIdNamesWasFocused: false,
      showExpiredAlert: false,
      expiredAlertMsg: '',
      selectionGroupModalShown: false,
      reqLicenseModalShown: false,
      woltSettingsPresent: false,
      boltSettingsPresent: false,
      fudySettingsPresent: false,
      hasAvailability: false,
    };
  },
  created() {
    if (this.getUserData.roleNames[0].toLowerCase() === 'dealer') {
      if (this.$route.params.id && this.$route.params.id === 'new') {
        this.fetchArea(this.getUserData.areaId).then(() => {
          this.form.settings.venueSelfSettings.timeZone = this.getActiveArea.timeZone;
          this.form.settings.venueSelfSettings.useDayLigthSavingTime = this.getActiveArea.useDayLigthSavingTime;
        })
      }
      this.fetchGroupData(`VenueGroups?areaId=${this.getUserData.areaId}`).then(data =>
        this.venueGroupsData.push(...data),
      );
    }
    if (this.getUserData.roleNames[0].toLowerCase() === 'admin') {
      this.getActiveAreaId
        ? this.fetchGroupData(`VenueGroups?areaId=${this.getActiveAreaId}`).then(data =>
          this.venueGroupsData.push(...data),
        )
        : this.fetchGroupData(`VenueGroups`).then(data => this.venueGroupsData.push(...data));
    }
  },
  props: {
    venue: {
      type: Object,
    },
    isFormEditable: {
      type: Boolean,
      default: false,
    },
    isScheduleLoading: {
      type: Boolean,
      default: false,
    },
    venueGroupFormId: {
      type: Number,
      default: 0,
    },
    isFormLoading: {
      type: Boolean,
      default: false,
    },
    clearForm: {
      type: Boolean,
      default: false,
    },
    shouldPopulateForm: {
      type: Boolean,
      default: false,
    },
    isCheckingRk: {
      type: Boolean,
      default: false,
    },
    isCheckingWolt: {
      type: Boolean,
      default: false,
    },
    isCheckingFudy: {
      type: Boolean,
      default: false,
    },
    isCheckingBolt: {
      type: Boolean,
      default: false,
    },
  },
  watch: {
    'form.name'(newVal) {
      this.$emit('nameUpdate', newVal);
    },
    'form.settings.venueSelfSettings.useCustomTimeZone'(newVal) {
      if (newVal) {
        if (this.$route.params.id && this.$route.params.id === 'new') {
          this.form.settings.venueSelfSettings.timeZone = 0
          this.form.settings.venueSelfSettings.useDayLigthSavingTime = false
        } else {
          this.form.settings.venueSelfSettings.timeZone = this.venue.settings.venueSelfSettings.timeZone
          this.form.settings.venueSelfSettings.useDayLigthSavingTime = this.venue.settings.venueSelfSettings.useDayLigthSavingTime
        }
      } else {
        if (this.$route.params.id && this.$route.params.id === 'new') {
          this.form.settings.venueSelfSettings.timeZone = this.getActiveArea.timeZone
          this.form.settings.venueSelfSettings.useDayLigthSavingTime = this.getActiveArea.useDayLigthSavingTime
        } else {

          this.form.settings.venueSelfSettings.timeZone = this.venue.areaTimeZone.timeZone
          this.form.settings.venueSelfSettings.useDayLigthSavingTime = this.venue.areaTimeZone.useDayLigthSavingTime
        }
      }
    },
    clearForm(newVal) {
      if (newVal) {
        this.resetForm();
      }
    },
    venue: {
      immediate: true,
      deep: true,
      handler() {
        if (this.shouldPopulateForm === true && Object.prototype.hasOwnProperty.call(this.venue, 'settings')) {
          this.populateForm();
          this.isNew = false;
        }
      },
    },
    shouldPopulateForm(newVal) {
      if (newVal) {
        this.populateForm();
        this.isNew = false;
      }
    },
    isSelfImageSourceId(e) {
      if (e === true) this.form.settings.venueSelfSettings.imageSourceId = null;
    },
  },
  computed: {
    ...mapGetters({
      getRoles: 'app/getRoles',
      getLanguages: 'venues/getLanguages',
      getDeliveryMethods: 'venues/getDeliveryMethods',
      getActiveVenueGroupId: 'venueGroups/getActiveVenueGroupId',
      getActiveAreaId: 'areas/getActiveAreaId',
      getActiveArea: 'areas/getItem',
      getGeneralSettings: 'app/getGeneralSettings',
      getIdNames: 'venues/getIdNames',
      getMenu: 'venues/getMenu',
      getUserData: 'app/getUserData',
    }),
    languages() {
      return this.getLanguages;
    },
    deliveryMethods() {
      return this.getDeliveryMethods;
    },
    venueStatus() {
      if (!Object.prototype.hasOwnProperty.call(this.venue, 'isOnline')) return { color: 'white' };
      else return VENUE_STATUSES.find(el => el.value === this.venue.isOnline);
    },
    timeFormat() {
      let timeFormat = this.dateTimeFormats.timeFormat === 1 ? 'hh:mm' : 'HH:mm';
      return timeFormat;
    },
    dateFormat() {
      let dateSeparator = this.dateTimeFormats.dateSeparator;
      let dateFormat =
        this.dateTimeFormats.dateOrder === 1
          ? `dd${dateSeparator}MM${dateSeparator}yyyy`
          : `MM${dateSeparator}dd${dateSeparator}yyyy`;
      return dateFormat;
    },
    isLicenseEditDisabled() {
      return this.getRoles.includes('Manager') || this.getRoles.includes('Dealer');
    },
    isRequestLicenseAllowed() {
      return this.getRoles[0].toLowerCase() === 'dealer';
    },
  },
  methods: {
    ...mapActions({
      updateMenuToDB: 'venues/updateMenuToDB',
      fetchIdNames: 'venues/fetchIdNames',
      fetchMenu: 'venues/fetchMenu',
      fetchArea: 'areas/fetchItem',
      editVenueGroup: 'venues/changeItemToDB',
      addLicReq: 'licRequests/addItemToDB',
      fetchGroupData: 'venueGroups/fetchData',
    }),
    ...mapMutations({
      changeShowExpMsg: 'app/changeShowExpMsg',
      setMenu: 'venues/setMenu',
      setLastUpdateTime: 'venues/setLastUpdateTime',
    }),
    isRequiredField(field) {
      let res = true;
      switch (field) {
        case 'boltCurrencyIdent':
          if (!this.boltSwitch) res = false;
          break;
        case 'woltCurrencyIdent':
          if (!this.woltSwitch) res = false;
          break;
        case 'fudyCurrencyIdent':
          if (!this.fudySwitch) res = false;
          break;
        case 'boltRootSelectorIdent':
          if (!this.boltSwitch) res = false;
          break;
        case 'woltRootSelectorIdent':
          if (!this.woltSwitch) res = false;
          break;
        case 'fudyRootSelectorIdent':
          if (!this.fudySwitch) res = false;
          break;
        case 'discountId':
          res = false;
          break;
        case 'pickUpOrderTypeIdent':
          res = false;
          break;
      }
      return res;
    },
    changeGroupId(id) {
      this.form.groupId = id;
    },
    validateForm(ref) {
      this.isFormValid = this.$refs[ref].validate();

      if (!this.form.settings.venueSelfSettings.imageSourceId && !this.isSelfImageSourceId) {
        this.isFormValid = false;
        this.$toast.open({ message: 'Please select image source id or select this venue!', type: TOAST_TYPES.ERROR });
      }

      if (this.form.settings.venueSelfSettings.autoAcceptTimeInSeconds > 420) {
        this.$toast.open({ message: 'Auto accept time must be under 7 minutes', type: TOAST_TYPES.ERROR });
        return;
      }

      if (!this.form.groupId) {
        if (!this.venue.groupId && !this.getActiveVenueGroupId && !this.venueGroupFormId)
          this.selectionGroupModalShown = true;
        else
          this.form.groupId = this.venue.groupId
            ? this.venue.groupId
            : this.getActiveVenueGroupId
              ? this.getActiveVenueGroupId
              : this.venueGroupFormId;
      }
      if (this.isFormValid) {
        let formToSend = JSON.parse(JSON.stringify(this.form));
        let defCookingTime = Number(formToSend.settings.venueSelfSettings.defaultCookingTimeInMinutes);
        let autoAcceptTime = Number(formToSend.settings.venueSelfSettings.autoAcceptTimeInSeconds);
        let maxCookingTime = Number(formToSend.settings.venueSelfSettings.maxCookingTimeInMinutes);
        let defaultDelayTime = Number(formToSend.settings.venueSelfSettings.defaultDelayTimeInMinutes);
        formToSend.settings.venueSelfSettings.defaultCookingTimeInMinutes = defCookingTime;
        formToSend.settings.venueSelfSettings.autoAcceptTimeInSeconds = autoAcceptTime;
        formToSend.settings.venueSelfSettings.maxCookingTimeInMinutes = maxCookingTime;
        formToSend.settings.venueSelfSettings.defaultDelayTimeInMinutes = defaultDelayTime;
        delete formToSend.status;
        delete formToSend.isOnline;

        if (!this.form.settings.venueSelfSettings.useCustomTimeZone) {
          formToSend.settings.venueSelfSettings.timeZone = null
          formToSend.settings.venueSelfSettings.useDayLigthSavingTime = this.venue.areaTimeZone.isDayLigthSaving
        }
        if (!this.woltSwitch) {
          formToSend.settings.woltSettings = null;
          this.form.settings.woltSettings = {
            url: '',
            login: '',
            pwd: '',
            venueId: '',
            apiKey: '',
          };
        } else {
          formToSend.settings.woltSettings.venueId = formToSend.settings.woltSettings.venueId.trim()
        }

        if (!this.boltSwitch) {
          formToSend.settings.boltSettings = null;
          this.form.settings.boltSettings = {
            url: '',
            providerId: '',
          };
        }
        if (!this.fudySwitch) {
          formToSend.settings.fudySettings = null;
          this.form.settings.boltSettings = {
            url: '',
            venueId: '',
            apiKey: '',
          };
        } else {
          formToSend.settings.fudySettings.venueId = formToSend.settings.fudySettings.venueId.trim()
        }
        this.$emit('submitForm', 'venue', formToSend);
        this.checkAndUpdateLicense();
      } else {
        this.isSettingsOpen = {
          rKeeperSettings: true,
          woltSettings: true,
          venueSelfSettings: true,
          boltSettings: true,
          licenses: true,
        };
        this.$toast.open({ message: 'Please fill in required fields!', type: TOAST_TYPES.ERROR });
      }
    },
    resetForm() {
      this.$emit('resetForm');
    },
    selectLang(e, key) {
      if (key === 'defaultMenuLanguageId') {
        this.form.settings.rKeeperSettings.defaultMenuLanguageId = e;
      }
    },
    populateForm() {
      if (this.venue.settings) {
        this.checkAndUpdateLicense();
      }

      if (this.venue && Object.prototype.hasOwnProperty.call(this.venue, 'settings')) {
        this.schedule =
          this.venue.info?.schedule && this.venue.info.schedule.availability.length > 0
            ? { ...this.venue.info.schedule }
            : { availability: [...DEFAULT_SCHEDULE] };
        this.form = {
          id: this.venue.id,
          name: this.venue.name,
          isOnline: this.venue.isOnline,
          menuUpdateTimeUtc: this.venue.menuUpdateTimeUtc,
          isWoltLicensed: this.venue.isWoltLicensed,
          isBoltLicensed: this.venue.isBoltLicensed,
          settings: {
            rKeeperSettings: {
              ip: this.venue.settings.rKeeperSettings.ip,
              port: this.venue.settings.rKeeperSettings.port,
              login: this.venue.settings.rKeeperSettings.login,
              pwd: this.venue.settings.rKeeperSettings.pwd,
              stationIdent: this.venue.settings.rKeeperSettings.stationIdent,
              tableIdent: this.venue.settings.rKeeperSettings.tableIdent,
              waiterIdent: this.venue.settings.rKeeperSettings.waiterIdent,
              receiptMaketIdent: this.venue.settings.rKeeperSettings.receiptMaketIdent,
              woltRootSelectorIdent: this.venue.settings.rKeeperSettings.woltRootSelectorIdent,
              boltRootSelectorIdent: this.venue.settings.rKeeperSettings.boltRootSelectorIdent,
              fudyRootSelectorIdent: this.venue.settings.rKeeperSettings.fudyRootSelectorIdent,
              woltCurrencyIdent: this.venue.settings.rKeeperSettings.woltCurrencyIdent,
              boltCurrencyIdent: this.venue.settings.rKeeperSettings.boltCurrencyIdent,
              fudyCurrencyIdent: this.venue.settings.rKeeperSettings.fudyCurrencyIdent,
              voidIdent: this.venue.settings.rKeeperSettings.voidIdent,
              orderTypeIdent: this.venue.settings.rKeeperSettings.orderTypeIdent,
              taxIdent: this.venue.settings.rKeeperSettings.taxIdent,
              pickUpOrderTypeIdent: this.venue.settings.rKeeperSettings.pickUpOrderTypeIdent,
              comboTaxRate: this.venue.settings.rKeeperSettings.comboTaxRate,
              printPurposeIdent: this.venue.settings.rKeeperSettings.printPurposeIdent,
              defaultMenuLanguageId: this.venue.settings.rKeeperSettings.defaultMenuLanguageId,
              defaultDeliveryMethod: this.venue.settings.rKeeperSettings.defaultDeliveryMethod,
              discountId: this.venue.settings.rKeeperSettings.discountId,
              isPayOrderEnabled: this.venue.settings.rKeeperSettings.isPayOrderEnabled,
              isDebugMode: this.venue.settings.rKeeperSettings.isDebugMode,
              isAutoPrintEnabled: this.venue.settings.rKeeperSettings.isAutoPrintEnabled,
              isUseComboName: this.venue.settings.rKeeperSettings.isUseComboName,
              isUseKds: this.venue.settings.rKeeperSettings.isUseKds,
            },

            woltSettings: this.venue.settings.woltSettings
              ? {
                url: this.venue.settings.woltSettings.url,
                login: this.venue.settings.woltSettings.login,
                pwd: this.venue.settings.woltSettings.pwd,
                venueId: this.venue.settings.woltSettings.venueId,
                apiKey: this.venue.settings.woltSettings.apiKey,
              }
              : {
                url: '',
                login: '',
                pwd: '',
                venueId: '',
                apiKey: '',
              },
            fudySettings: this.venue.settings.fudySettings
              ? {
                url: this.venue.settings.fudySettings.url,
                venueId: this.venue.settings.fudySettings.venueId,
                apiKey: this.venue.settings.fudySettings.apiKey,
              }
              : {
                url: '',
                venueId: '',
                apiKey: '',
              },
            venueSelfSettings: {
              woltExpiredDateUtc: new Date(this.venue.settings.venueSelfSettings.woltExpiredDateUtc),
              boltExpiredDateUtc: new Date(this.venue.settings.venueSelfSettings.boltExpiredDateUtc),
              fudyExpiredDateUtc: new Date(this.venue.settings.venueSelfSettings.fudyExpiredDateUtc),
              defaultCookingTimeInMinutes: this.venue.settings.venueSelfSettings.defaultCookingTimeInMinutes,
              maxCookingTimeInMinutes: this.venue.settings.venueSelfSettings.maxCookingTimeInMinutes,
              defaultDelayTimeInMinutes: this.venue.settings.venueSelfSettings.defaultDelayTimeInMinutes,
              autoAcceptTimeInSeconds: this.venue.settings.venueSelfSettings.autoAcceptTimeInSeconds,
              alwaysDefaultDelayTime: this.venue.settings.venueSelfSettings.alwaysDefaultDelayTime,
              imageSourceId: this.venue.settings.venueSelfSettings.imageSourceId,
              isPrintOrderEnabled: this.venue.settings.venueSelfSettings.isPrintOrderEnabled,
              isAutoAcceptEnabled: this.venue.settings.venueSelfSettings.isAutoAcceptEnabled,
              isAutoPickedUpEnabled: this.venue.settings.venueSelfSettings.isAutoPickedUpEnabled,
              isAutoSyncMenuEnabled: this.venue.settings.venueSelfSettings.isAutoSyncMenuEnabled,
              isSingleModifierModeEnabled: this.venue.settings.venueSelfSettings.isSingleModifierModeEnabled,
              isPayOrderAfterAcceptEnabled: this.venue.settings.venueSelfSettings.isPayOrderAfterAcceptEnabled,
              delayOrderSaving: this.venue.settings.venueSelfSettings.delayOrderSaving,
              useCustomTimeZone: this.venue.settings.venueSelfSettings.useCustomTimeZone,
              timeZone: this.venue.settings.venueSelfSettings.useCustomTimeZone ? this.venue.settings.venueSelfSettings.timeZone : this.venue.areaTimeZone.timeZone,
              useDayLigthSavingTime: this.venue.settings.venueSelfSettings.useCustomTimeZone ? this.venue.settings.venueSelfSettings.useDayLigthSavingTime : this.venue.areaTimeZone.useDayLigthSavingTime
            },
            boltSettings: this.venue.settings.boltSettings
              ? {
                url: this.venue.settings.boltSettings.url,
                providerId: this.venue.settings.boltSettings.providerId,
              }
              : {
                url: '',
                providerId: '',
              },
          },
        };

        if (!this.venue.settings.venueSelfSettings.imageSourceId) this.isSelfImageSourceId = true;

        if (Object.prototype.hasOwnProperty.call(this.venue.settings.venueSelfSettings, 'boltExpiredDateUtc')) {
          this.expDateUnlimModel.boltExpiredDateUtc = false;
        } else {
          this.expDateUnlimModel.boltExpiredDateUtc = true;
        }
        if (Object.prototype.hasOwnProperty.call(this.venue.settings.venueSelfSettings, 'woltExpiredDateUtc')) {
          this.expDateUnlimModel.woltExpiredDateUtc = false;
        } else {
          this.expDateUnlimModel.woltExpiredDateUtc = true;
        }
        if (Object.prototype.hasOwnProperty.call(this.venue.settings.venueSelfSettings, 'fudyExpiredDateUtc')) {
          this.expDateUnlimModel.fudyExpiredDateUtc = false;
        } else {
          this.expDateUnlimModel.fudyExpiredDateUtc = true;
        }

        const woltSetPresent = Object.prototype.hasOwnProperty.call(this.venue.settings, 'woltSettings');
        const boltSetPresent = Object.prototype.hasOwnProperty.call(this.venue.settings, 'boltSettings');
        const fudySetPresent = Object.prototype.hasOwnProperty.call(this.venue.settings, 'fudySettings');
        const hasAvailab = Object.prototype.hasOwnProperty.call(this.venue.info, 'availability');

        this.woltSettingsPresent = woltSetPresent;
        this.boltSettingsPresent = boltSetPresent;
        this.fudySettingsPresent = fudySetPresent;
        this.hasAvailability = hasAvailab;

        this.woltSwitch = woltSetPresent;
        this.boltSwitch = boltSetPresent;
        this.fudySwitch = fudySetPresent;
      }

      let venueGroupId;
      if (this.venue.groupId) {
        venueGroupId = this.venue.groupId;
      } else if (this.venueGroupFormId) {
        venueGroupId = this.venueGroupFormId;
      } else {
        venueGroupId = '';
      }
      if (this.getUserData.areaId) {
        this.fetchIdNames(`/venues/id-name?areaId=${this.getUserData.areaId}`).then(() => {
          this.idNamesLoading = false;
        });
      } else if (this.getActiveAreaId) {
        this.fetchIdNames(`/venues/id-name?areaId=${this.getActiveAreaId}`).then(() => {
          this.idNamesLoading = false;
        });
      } else {
        this.fetchIdNames(`/venues/id-name?groupId=${venueGroupId}`).then(() => {
          this.idNamesLoading = false;
        });
      }

      if (this.getRoles[0].toLowerCase() === 'manager' || this.$route.params.id) {
        this.venueId = this.$route.params.id;
      }
    },
    saveEdit(form) {
      this.editVenueGroup(form).then(() => {
        this.isVenueGroupModalShow = false;
        this.$toast.open({ message: 'Restaurant subgroup has been successfully edited!', type: TOAST_TYPES.SUCCESS });
      });
    },
    checkRk() {
      this.$emit('checkConnection', 'rk');
    },
    checkWolt() {
      this.$emit('checkConnection', 'wolt');
    },
    checkFudy() {
      this.$emit('checkConnection', 'fudy');
    },
    checkBolt() {
      this.$emit('checkConnection', 'bolt');
    },
    changeVenueStatus(disId, isOnline) {
      let dataToSend = {
        id: this.venue.id,
        distributorId: disId,
        isOnline: isOnline,
      };
      this.$emit('changeCurrentVenueStatus', dataToSend);
    },
    changeVenueSchedule(schedule) {
      this.$emit('changeSchedule', schedule);
    },
    openVenueModal() {
      this.isVenueModalShow = true;
    },
    closeVenueModal() {
      this.isVenueModalShow = false;
    },
    async openMenuModal() {
      if (localStorage.getItem(this.venue.id)) {
        const menuFromLocalStorage = JSON.parse(localStorage.getItem(this.venue.id));
        this.setMenu(menuFromLocalStorage.menu);
        this.setLastUpdateTime(menuFromLocalStorage.lastUpdateTime);
        this.isMenuModalShow = true;
      } else {
        const menu = await this.fetchMenu(this.venue.id);
        const date = new Date();
        this.setLastUpdateTime(date);
        localStorage.setItem(this.venue.id, JSON.stringify({ menu, lastUpdateTime: date }));
        this.isMenuModalShow = true;
      }
    },
    closeMenuModal() {
      this.isMenuModalShow = false;
    },
    openVenueScheduleModal() {
      this.isScheduleModalShow = true;
    },
    closeVenueScheduleModal() {
      this.isScheduleModalShow = false;
    },
    openVenueGroupModal() {
      this.isVenueGroupModalShow = true;
    },
    openAreaModal() {
      this.isAreaModalShow = true;
    },
    copyClipboard(iconState, value) {
      navigator.clipboard.writeText(value);
      this[iconState] = true;
      setTimeout(() => {
        this[iconState] = false;
      }, 1000)
    },
    getItemText(item) {
      return item.rKeeperName ? `${item.name} - ${item.rKeeperName}` : item.name;
    },
    unlimCheckboxChange(val, key) {
      if (val) {
        this.form.settings.venueSelfSettings[key] = null;
      }
    },
    dateTimeChanged(key) {
      this.expDateUnlimModel[key] = false;
    },
    getIdNamesFocused() {
      if (!this.getIdNamesWasFocused && this.isNew) {
        this.getIdNamesFocused = true;
        this.idNamesLoading = true;
        let venueGroupId;
        if (this.venue.groupId) {
          venueGroupId = this.venue.groupId;
        } else if (this.venueGroupFormId) {
          venueGroupId = this.venueGroupFormId;
        } else {
          venueGroupId = '';
        }
        if (this.getUserData.areaId) {
          this.fetchIdNames(`/venues/id-name?areaId=${this.getUserData.areaId}`)
            .then(() => {
              this.idNamesLoading = false;
            })
            .catch(() => {
              this.idNamesLoading = false;
            });
        } else if (this.getActiveAreaId) {
          this.fetchIdNames(`/venues/id-name?areaId=${this.getActiveAreaId}`)
            .then(() => {
              this.idNamesLoading = false;
            })
            .catch(() => {
              this.idNamesLoading = false;
            });
        } else {
          this.fetchIdNames(`/venues/id-name?groupId=${venueGroupId}`)
            .then(() => {
              this.idNamesLoading = false;
            })
            .catch(() => {
              this.idNamesLoading = false;
            });
        }

      }
    },
    selectImageSourceId(e) {
      this.form.settings.venueSelfSettings.imageSourceId = e;
    },
    checkAndUpdateLicense() {
      const isBoltActive = Object.prototype.hasOwnProperty.call(this.venue.settings, 'boltSettings');
      const isWoltActive = Object.prototype.hasOwnProperty.call(this.venue.settings, 'woltSettings');
      const isFudyActive = Object.prototype.hasOwnProperty.call(this.venue.settings, 'fudySettings');
      let isWoltLicensed = this.venue.isWoltLicensed;
      let isBoltLicensed = this.venue.isBoltLicensed;
      let isFudyLicensed = this.venue.isFudyLicensed;

      if (isWoltActive && !isWoltLicensed && isBoltActive && !isBoltLicensed && isFudyActive && !isFudyLicensed) {
        this.showExpiredAlert = true;
        this.expiredAlertMsg = 'Wolt, Bolt and Fudy licenses are expired!';
        return;
      }

      if (isWoltActive && !isWoltLicensed) {
        this.expiredAlertMsg = 'Wolt license is expired!';
        this.showExpiredAlert = true;
        return;
      }

      if (isBoltActive && !isBoltLicensed) {
        this.expiredAlertMsg = 'Bolt license is expired!';
        this.showExpiredAlert = true;
        return;
      }
      if (isFudyActive && !isFudyLicensed) {
        this.expiredAlertMsg = 'Fudy license is expired!';
        this.showExpiredAlert = true;
        return;
      }

      this.showExpiredAlert = false;
    },
    changeExpLicenseHandler() {
      this.changeShowExpMsg(false);
    },
    requestLicenseHandler() {
      this.reqLicenseModalShown = true;
    },
    async sendReqLicHandler(comment) {
      this.reqLicenseModalShown = false;
      let form = {
        venueId: this.venue.id,
        comment: comment,
      };
      try {
        let data = await this.addLicReq(form);
        if (data.status <= 300) {
          this.$toast.open({ message: 'License request has successfully been sent!', type: TOAST_TYPES.SUCCESS });
        }
      } catch (err) {
        this.$toast.type({ message: 'Unexpected error!', type: TOAST_TYPES.ERROR });
      }
    },
    statusColor(provider) {
      const has = Object.prototype.hasOwnProperty.call(this.venue.info, 'availability');
      if (provider === 'wolt' && has && this.woltSettingsPresent) {
        return this.venue.info.availability.wolt ? 'green' : 'red';
      }
      if (provider === 'bolt' && has && this.boltSettingsPresent) {
        return this.venue.info.availability.bolt ? 'green' : 'red';
      }
      if (provider === 'fudy' && has && this.fudySettingsPresent) {
        return this.venue.info.availability.fudy ? 'green' : 'red';
      }
      return '#ccc';
    },
  },
};
</script>

<style lang="scss" scoped>
.status-circle {
  width: 40px;
  height: 40px;
  padding: 5px;
  text-align: center;
  border-radius: 50%;
  border: 1px solid gray;
}

.datetime-unlim-checkbox {
  border: 1px solid transparent;
  border-radius: 12px;
  font-size: 18px !important;
  background: #f0f0f0;
  height: 58px;
  box-sizing: border-box;
}

.status-tag {
  border: 1px solid #ccc;
  border-radius: 16px;
  padding: 7px;
  display: flex;
  align-items: center;
}
</style>
